article#Profile {
    font-family: "Roboto", sans-serif;
    margin: 30px auto;
    max-width: 800px;
    min-width: 300px;
    width: 80%;
    padding: 0px;
    background-color: #404040;
    color: white;
    min-height: 40vh;
}

.Profile-Top {
    line-height: 75px;
    text-align: center;
    height: 75px;
    display: flex;
    width: 100%;
    background-color: #202020;
    padding: 15px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.Profile-Top h2 {
    line-height: 75px;
}

#Profile > div {
    padding: 0px;
    box-sizing: border-box;
}

.Profile-Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Profile-Inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0px;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    width: 100%;
    padding: 0% !important;
}

.Profile-Inputs input,
.Profile-Inputs label,
.Profile-Inputs select,
.Profile-Form textarea {
    margin: 0px;
    padding: 0px;
    width: fit-content;
    color: whitesmoke;
    width: 100%;
    min-width: 150px;
    height: 25px;
    border-radius: 10px;
    border: none;
    margin: 10px;
    padding: 0px 15px;
    box-sizing: border-box;
    font-size: 15px;

    resize: none;
}

.Profile-Settings label {
    text-align: left !important;
    margin: 0px !important;
    line-height: normal !important;
    width: 100%;
    min-width: 150px;
}

.Profile-Settings textarea, .Profile-Settings select {
    margin-top: 0px !important;
}

.Profile-Settings {
    display: flex !important;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.Profile-Form textarea {
    height: 100px !important;
    width: 100%;
}

.Profile-Inputs label {
    text-align: right;
}

.Profile-Form input,
.Profile-Inputs select,
.Profile-Form textarea {
    color: whitesmoke;
    width: 100%;
    min-width: 150px;
    height: 25px;
    border-radius: 10px;
    border: none;
    margin: 10px;
    padding: 0px 15px;
    box-sizing: border-box;
    font-size: 15px;
    background-color: #202020;
}

.Profile-Form input:focus {
    outline: none;
}

.Profile-Form button {
    color: whitesmoke;
    width: 40%;
    min-width: 150px;
    height: 25px;
    border-radius: 10px;
    border: none;
    margin: 10px;
    padding: 0px 15px;
    box-sizing: border-box;
    font-size: 15px;
    background-color: #202020;
    cursor: pointer;
}

.Profile-Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: black 1px solid;
}

.Profile-Section h3 {
    margin: 0px;
    margin-top: 15px;
    padding: 0px;
    width: 100%;
    text-align: center;
    font-size: 20px;
}
