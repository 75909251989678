
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

footer {
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    background-color: #404040;
    box-sizing: border-box;
    height: 100px;
}

footer nav ul#Footer-Nav {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer nav a li {
    text-transform: uppercase;
    font-size: 24px;
    display: inline;
    text-align: center;
    line-height: calc(100% - 20px);
    color: white;
    text-decoration: none;
    margin: 10px;

}

footer #Footer-Nav a {
    box-sizing: border-box;
    min-height: 25px;
    text-align: center;
    line-height: 30px;
}

footer #Footer-Nav a:not(:last-child) {
    border-right: 3px solid white;
}

footer a {
    color: white;
    text-decoration: none;
}

footer nav {
    text-align: center;
}

footer #Logo {
    margin: 10px;
    margin-left: 20px;
    height: calc(100% - 20px);
}

footer #Contact-Links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    line-height: calc((100% - 20px) / 2);
    margin: 10px;
    margin-right: 20px;
    height: calc(100% - 20px);
}

footer #Contact-Links a {
    text-align: center;
    line-height: calc(100% - 20px)/ 2;
    color: white;
    text-decoration: none;
    margin: 10px;
    display: block;
}