@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

.Blog-Post {
    font-family: "Roboto", sans-serif;
    margin: 30px auto;
    max-width: 800px;
    min-width: 150px;
    width: 50%;
    padding: 0px;
    background-color: #404040;
    color: white;
}

.Blog-Post-Top {
    height: 75px;
    display: flex;
    width: 100%;
    background-color: #202020;
    padding: 15px;
    box-sizing: border-box;
    justify-content: space-between;
}

.Blog-Post-Top-Left {
    line-height: 45px;
}

.Blog-Post-Top-Right {
    line-height: 45px / 2;
    text-align: right;
}

.Blog-Post-Top-Right,
.Blog-Post-Top-Left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Blog-Post-Top p,
h2 {
    margin: 0px;
    padding: 0px;
}

.Blog-Post-Content {
    padding: 15px;
    box-sizing: border-box;
}

.Blog-Post-Content pre {
    font-family: "Roboto", sans-serif;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.Blog-Post-Button {
    padding: 10px 15px;
    background-color: #202020;
    border: 1px solid #404040;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    font-size: 0.8em;
    font-weight: bold;
    display: inline-block;
    transition: all 0.2s ease-in-out;
}

.Blog-Post-Button:hover {
    background-color: #404040;
    border: 1px solid #202020;
}

.Blog-Post .editError {
    margin: 5px;
    margin-left: 15px;
}

.Blog-Post-Content a {
    color: #ffffff;
}

.Blog-Post-Content a:hover {
    color: #c6c6c6;
}

.Author-View .Blog-Post-Content {
    padding-top: 2px;
}

.Post-Buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}