#Staff-Page {
    font-family: "Roboto", sans-serif;
    margin: 30px auto;
    max-width: 1200px;
    min-width: 750px;
    width: 90%;
    padding: 0px;
    background-color: #404040;
    color: white;
    height: 850px;
}

#Staff-Page > h3 {
    text-align: center;
    padding: 15px;
    width: 100%;
    background-color: #202020;
    margin: 0%;
    box-sizing: border-box;
    height: 50px;
}

#Staff-Page > aside { 
    float:left;
    position: inline;
    height: 800px;
    overflow-y: scroll;
    width: 250px;
    margin: 0%;
    box-sizing: border-box;
    border-right: 1px solid #202020;
}

#Staff-Page > aside > h4 {
    border-top: 1px solid #303030;
    margin-top: 0px;
    margin-bottom: 1px;
    text-align: center;
    box-sizing: border-box;
    padding: 15px;
    background-color: #202020;
}

#Staff-Page > aside li {
    color: white;
    text-decoration: none;
    list-style: none;
}

#Staff-Page > aside a:hover {
    background-color: #303030;
    cursor: pointer;
    border: 0px;
}

#Staff-Page > aside .active{
    box-sizing: border-box;
    background-color: #303030;
    border: 0px;
}

#Staff-Page a {
    text-decoration: none;
    line-height: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    display: block;
    height: 50px;
    border-bottom: 1px solid #353535;
}

#Staff-Page ul {
    padding: 0px;
    margin: 0px;
}

#Staff-Page ul a:last-child {
    margin-bottom: 15px;
}

#Staff-Page > aside ul h4 {
    border-top: 1px solid #303030;
    box-sizing: border-box;
    margin: 0%;
    padding: 0px;
    text-align: center;
    box-sizing: border-box;
    padding: 15px;
    background-color: #252525;
}

.Staff-Profile {
    float: right;
    box-sizing: border-box;
    width: calc(100% - 250px);
    height: 800px;
}

.Staff-Profile > h3 {
    border-top: 1px solid #303030;
    border-bottom: 1px solid #303030;
    margin-top: 0px;
    margin-bottom: 1px;
    height: 50px;
    text-align: center;
    box-sizing: border-box;
    padding: 15px;
    background-color: #202020;
}

.Staff-Profile > aside {
    float: left;
    width: 300px;
    height: 715px;
    margin: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    
    border: 1px solid #505050;
    background-image: url(https://assets.darklordbazz.com/img/botSiteAssets/skinback.jpg);
    background-size: 800px;
}

.Staff-Profile > section {
    float: right;
    height: 750px;
    width: calc(100% - 330px);
    padding: 30px;
    box-sizing: border-box;
}

.Staff-Profile > section > p {
    margin-top: 20%;
    height: auto;
    text-align: left;
    width: 90%;
    word-break: break-all;
    hyphens: auto;
}

.Staff-Profile > aside > img {
    width: 250px;
    height: auto;
}

.Staff-Stats {
    margin: auto;
    margin-top: 15%;
    display: flex;
    justify-content: space-between;
    width: 75%;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.Staff-Stat-Card {
    background-color: #303030;
    text-align: center;
    height: 60px;
    width: 60px;
    margin: 15px;
    margin-top: 15px;
    box-sizing: border-box;
    text-transform: capitalize;
} 

.Staff-Stat-Card > h4 {
    background-color: #202020;
    margin: 0px;
    padding: 0px;
    line-height: 30px;
}

.Staff-Stat-Card > p {
    margin-top: 40%;
    margin: 0px;
    padding: 0px;
    line-height: 25px;
}

.Staff-Stat-Card.Stats-One {
    width: 175px;

}